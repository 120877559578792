<template>
  <v-data-table
    :headers="headers"
    :items="arrayitemFlota"
     :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Flotas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>


         <v-text-field
        v-model="search"
        append-icon="search"
        label="buscar"
        single-line
        hide-details
      ></v-text-field>


        <v-spacer></v-spacer>
        <v-dialog v-model="dialog"  max-width="500px">
          
          <template>
            <!--v-btn color="primary" dark class="mb-2" >
              <v-icon dark>add</v-icon>
            </v-btn -->
          </template>
          <v-card>


            <v-toolbar dark color="indigo">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>
            </v-toolbar>




            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field v-model="editedItem.nombre_flota" prepend-icon="commute" label="Nombre Flota" disabled></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-text-field v-model="editedItem.destinatario_primario" prepend-icon="email"  label="Destinatario Primario"></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="12">
                    <v-text-field v-model="editedItem.destinatario_secundario" prepend-icon="email" label="Destinatario secundario"></v-text-field>
                  </v-col>

                  <span style="caption">*cuentas de correos separadas por punto y coma</span>

                 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="success" dark @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">deseas deshabilitar flota?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon  small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";


  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
      arrayitemFlota: [],
      headers: [
        { text: 'Nombre Flota', value: 'nombre_flota' },
        { text: 'Destinatario Primario', value: 'destinatario_primario' },
        { text: 'Destinatario Secundario', value: 'destinatario_secundario' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      
      editedIndex: -1,
      editedItem: {
        nombre_flota: '',
        destinatario_primario: '',
        destinatario_secundario: '',
        id_empresa_fl:'',
        activo: 1
      },
      defaultItem: {
        nombre_flota: '',
        destinatario_primario: '',
        destinatario_secundario:'',
         id_empresa_fl:'',
        activo: 1
      }
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva flota' : 'Edicion flota'
      },

        ...mapState(['usuario', 'token']),

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.listar();
    },

    methods: {

        ...mapMutations(['mostrarLoading','ocultarLoading']),


      listar(){

               this.arrayitemFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`flotas/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                      
                        me.arrayitemFlota=response.data;

                       // alert(me.arrayitemFlota[0].nombre_flota);
                        me.ocultarLoading();

                          console.log(me.arrayitemFlota);
                    }).catch(function(error){ console.log(error); });

          },



      editItem (item) {
        this.editedIndex = this.arrayitemFlota.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.arrayitemFlota.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.arrayitemFlota.splice(this.editedIndex, 1)


         let config={headers:{token:this.token}};

          this.mostrarLoading({titulo:'Accediendo a datos'});

          let id=this.editedItem._id;

                    axios.put(`flotas/${id}`,
                      {'activo':0},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


        this.closeDelete()
        
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
         // Object.assign(this.arrayitemFlota[this.editedIndex], this.editedItem)
        //edicion de campos


                   let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`flotas/${id}`,
                      { 

                        'destinatario_primario': this.editedItem.destinatario_primario,
                        'destinatario_secundario': this.editedItem.destinatario_secundario,
                        'activo': this.editedItem.activo
                       },
                     config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                        this.close()

                  }).catch(e=>{
                        console.log(e)
                  });






        } 
      
      },

    },
  }
</script>